// GuestList.js
import React, { useState } from 'react';
import { Typography, Stack, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from '@emotion/react';
import axiosInstance from '../../common/api';

const GuestList = ({ isOwner, hasGuests, account, setError, setIsOpen, setIsLoading, triggerAccountEffect }) => {
  const theme = useTheme();

  const [isDialogDeleteGuestOpen, setIsDialogDeleteGuestOpen] = useState(false);
  const [guest, setGuest] = useState(null);

  const handleDeleteGuest = async (guest) => {
    setIsLoading(true);
    try {
      await axiosInstance.delete(`/account/guest/${account._id}/${guest.id}`);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      const errorMsg = error?.response?.data?.message;
      setError(errorMsg || 'Error during guest deletion.');
      setIsOpen(true);
      setIsLoading(false);
    } finally {
      setIsDialogDeleteGuestOpen(false);
      triggerAccountEffect();
    }
  };

  const handleOpenDeleteGuestDialog = (guest) => {
    setGuest(guest);
    setIsDialogDeleteGuestOpen(true);
  };

  const handleCloseDeleteGuestDialog = () => {
    setIsDialogDeleteGuestOpen(false);
  };

  return (
    hasGuests && account && account.guests && (
      <div>
        <Typography
          color={theme.palette.text.secondary}
          style={{ fontSize: '.75rem', marginTop: '2rem', marginBottom: '.5rem' }}>
          Invité{account.guests.length > 1 ? 's' : ''}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          flexWrap="wrap"
          style={{ overflow: 'auto', margin: 'auto' }}
        >
          {account.guests.map((guest, index) => (
            isOwner
              ? guest.accessRight === 'write'
                ? <Chip
                  color='warning'
                  key={guest._id || index}
                  label={guest.username}
                  onDelete={() => handleOpenDeleteGuestDialog(guest)}
                  deleteIcon={<CancelIcon style={{ background: 'secondary' }} />}
                  style={{ margin: '.25rem' }}
                />
                : <Chip
                  key={guest._id || index}
                  label={guest.username}
                  onDelete={() => handleOpenDeleteGuestDialog(guest)}
                  deleteIcon={<CancelIcon style={{ background: 'secondary' }} />}
                  style={{ margin: '.25rem' }}
                />
              : <Chip
                key={guest._id || index}
                label={guest.username}
              />
          ))}
        </Stack>

        {/* Confirmation Dialog */}
        <Dialog open={isDialogDeleteGuestOpen} onClose={handleCloseDeleteGuestDialog} >
          <DialogTitle>Confirmer</DialogTitle>
          <DialogContent>
            Arreter le partage avec "<strong>{guest && guest.username}</strong>" ?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteGuestDialog}>Annuler</Button>
            <Button onClick={() => handleDeleteGuest(guest)} color="secondary">
              Arreter le partage
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};

export default GuestList;
