import React, { forwardRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Slide, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpenseForm from './Forms/ExpenseForm';
import IncomeForm from './Forms/IncomeForm';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../common/api';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddFormButton = ({ parentMode, account, triggerExpenseEffect, triggerIncomeEffect }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(parentMode);
  const [tooltipMsg] = useState(`Ajouter ${parentMode === 'Expense' ? 'une dépense' : 'un revenu'}`);

  // const handleModeChange = (event) => {
  //   setMode(event.target.value);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setMode(parentMode);
    setOpen(false);
  };

  const onSave = async (formData) => {
    if (!account?._id) {
      navigate('/account', { state: { from: '/dashboard' } });
    } else {
      formData.accountId = account._id;
      return axiosInstance.post(`/${mode.toLowerCase()}/add`, formData)
        .then((response) => {
          console.log(response);
          return response;
        })
        .catch((error) => {
          throw new Error(error);
        });
    }

  };

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', margin: '0 .5rem .5rem 0' }}>
      <Tooltip title={tooltipMsg} style={{ marginLeft: '.75rem' }}>
        <Fab
          size="medium"
          color="primary"
          aria-label="add"
          onClick={handleClickOpen}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle
          style={{ background: theme.palette.primary.main, color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          Créer {mode === 'Expense' ? 'une dépense' : 'un revenu'}
          <Fab
            size="medium"
            aria-label="close"
            color="primary"
            onClick={handleClose}
            style={{ marginRight: '.5rem' }}
          >
            <CloseIcon />
          </Fab>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {/* <FormControl required sx={{ m: 1, minWidth: 120 }}>
            <Select
              labelId="mode-select-required-label"
              id="mode-select-required"
              value={mode}
              onChange={handleModeChange}
            >
              <MenuItem value="Expense" selected={mode === 'Expense'}>Expense</MenuItem>
              <MenuItem value="Income" selected={mode === 'Income'}>Income</MenuItem>
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl> */}
          {
            mode === 'Expense'
              ? <ExpenseForm onSave={onSave} triggerExpenseEffect={triggerExpenseEffect} account={account} />
              : <IncomeForm onSave={onSave} triggerIncomeEffect={triggerIncomeEffect} account={account} />
          }
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddFormButton;