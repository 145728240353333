import PaidIcon from '@mui/icons-material/Paid';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

const SourceIcons = [
  {
    name: 'Salaire',
    value: PaidIcon
  },
  {
    name: 'Freelance',
    value: EngineeringIcon
  },
  {
    name: 'Revenus locatifs',
    value: HolidayVillageIcon
  },
  {
    name: 'Prestations sociales',
    value: LoyaltyIcon
  },
  {
    name: 'Bourse',
    value: CurrencyExchangeIcon
  },
  {
    name: 'Vente d\'actifs',
    value: AccountBalanceWalletIcon
  },
  {
    name: 'Remboursement de prêt',
    value: AssignmentReturnIcon
  },
  {
    name: 'Cadeaux',
    value: CardGiftcardIcon
  },
  {
    name: 'Prime',
    value: LibraryAddIcon
  },
  {
    name: 'Remboursement d\'impôts',
    value: AssignmentReturnedIcon
  },
  {
    name: 'Autre',
    value: DashboardCustomizeIcon
  }
];

const SourceIconMapping = SourceIcons.reduce((acc, icon) => {
  acc[icon.name] = icon.value;
  return acc;
}, {});

export { SourceIcons, SourceIconMapping };