import { createTheme } from '@mui/material/styles';

const fontFamilies = {
  basic: 'PT Sans, sans-serif',
};

// Common color palette
const commonColors = {
  // primary: '#484D6D',
  primary: '#5C6BC0',
  pastelBlue: '#90CAF9',
  pastelPink: '#F48FB1',
  darkYellow: '#C9B51A',
  darkYellowLight: '#23200f',
  darkGray: '#333',
  lightGray: '#999',
  lightWhite: '#e3e3e3',
  lightWhiteBg: '#e3e3e331',
  darkBackground: '#202123',
  lightBackground: '#f0f0f0',
  paperBackground: '#ffffff',
  dynamicColor1: '#FF7043',
  dynamicColor2: '#66BB6A',
  dynamicColor3: '#FFD54F',
  dynamicColor4: '#5C6BC0',
  dynamicColor4Light: '#495596',
  dynamicColor5: '#FF4081',
  dynamicColor6: '#4FC3F7',
  dynamicColor7: '#FFB74D',
  dynamicColor8: '#9575CD',
  dynamicColor9: '#81C784',
  dynamicColor10: '#FF5252',
  dangerLight: '#ff0000',
  dangerDark: '#ff0000'
};

// Light mode theme
const lightTheme = createTheme({
  typography: {
    fontFamily: fontFamilies.basic
  },
  palette: {
    primary: {
      main: commonColors.primary,
      light: commonColors.dynamicColor4Light,
    },
    secondary: {
      main: commonColors.pastelPink,
    },
    background: {
      default: commonColors.lightBackground,
      paper: commonColors.paperBackground,
    },
    text: {
      primary: commonColors.darkGray,
      secondary: commonColors.lightGray,
      ternary: commonColors.lightWhiteBg,
      inversed: commonColors.lightWhite,
      danger: commonColors.dynamicColor10
    },
    type: 'light',
  },
});

// Dark mode theme
const darkTheme = createTheme({
  typography: {
    fontFamily: fontFamilies.basic
  },
  palette: {
    primary: {
      main: commonColors.primary,
      light: commonColors.darkYellowLight,
    },
    secondary: {
      main: commonColors.pastelPink,
    },
    background: {
      default: commonColors.darkBackground,
      paper: commonColors.darkBackground,
    },
    text: {
      primary: commonColors.lightWhite,
      secondary: commonColors.lightGray,
      ternary: commonColors.lightWhiteBg,
      inversed: commonColors.darkGray,
      danger: commonColors.dynamicColor10
    },
    type: 'dark',
  },
});

export { lightTheme, darkTheme };
