import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../services/authService';
import SnackMessage from '../SnackMessage';
import { SetSecureDataStorage, GetSecureDataStorage } from './HashAndStoreData';

const LoginForm = ({ setIsLoggedIn, setIsAdmin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    try {
      const response = await login(formData);

      if (response.error) {
        setErrorMessage(response.error);
        setOpen(true);
      } else {

        // Enregistrez les informations hachée d'identification dans le localStorage
        await SetSecureDataStorage(username, 'app_my_finance_user', true);
        await SetSecureDataStorage(password, 'app_my_finance_pass', true);
        setIsLoggedIn(true);
        setIsAdmin(response?.user?.role === 'Admin');
        navigate('/account', { state: { from: '/login' } });
      }

    } catch (error) {
      console.log('Err - LoginForm: ', error.message);
      setErrorMessage('An error occurred. Please try again later.');
      setOpen(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Your asynchronous code here
        const storedUsername = await GetSecureDataStorage('app_my_finance_user', true);
        const storedPassword = await GetSecureDataStorage('app_my_finance_pass', true);

        if (storedUsername && storedPassword) {
          console.log('🧑🏽‍💻 Auto-logged-in');
          const formData = new FormData();
          formData.append('username', storedUsername);
          formData.append('password', storedPassword);
          const response = await login(formData);

          if (!response.error) {
            // If the login is successful, redirect the user to the dashboard
            setIsLoggedIn(true);
            navigate('/account', { state: { from: '/login' } });
          }
        }
      } catch (error) {
        console.error('Error during automatic login:', error.message);
      }
    };

    fetchData(); // Call the async function immediately
  }, []);


  return (
    <Container maxWidth="sm" style={{ padding: '2rem', marginTop: '2rem' }}>
      <Typography variant="h6" color="primary">
        Login to the application !
      </Typography>
      <form
        onSubmit={handleSubmit}
      >
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ margin: '1rem 0' }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ margin: '1rem 0' }}
        />
        <SnackMessage message={errorMessage} severity="warning" open={open} handleClose={handleClose} title="Hum hum.." variant={'filled'} />
        <Button variant="contained" color="primary" type="submit" size="large" fullWidth style={{ marginTop: '1rem' }}>
          Login
        </Button>
      </form>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '1.5rem' }}>
        <Button
          component={Link}
          to="/register"
          variant="contained"
          color="secondary"
          size="small"
          style={{ fontSize: '.7rem' }}
        >
          Create Your Account
        </Button>
      </div>
    </Container>
  );
};

export default LoginForm;
