import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Grid, CircularProgress, Box, Alert, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axiosInstance from '../../common/api';
import SnackMessage from '../../SnackMessage';
import CategoryIcon from '@mui/icons-material/Category';
import AddIcon from '@mui/icons-material/Add';
import { SourceIconMapping } from '../../common/SourceIcons';
import { getTextColor } from '../../common/GetTextColor';

const IncomeForm = ({ onSave, triggerIncomeEffect, account }) => {
  const [sources, setSources] = useState([]);
  const [mostUsed, setMostUsed] = useState([]);
  const [amount, setAmount] = useState('');
  const [showAllProposals, setShowAllProposals] = useState(false);
  const [source, setSource] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [errors, setErrors] = useState({
    amount: '',
    source: '',
    date: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnacksMessage] = useState(null);
  const [severity, setSeverity] = useState('error');
  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const displayedProposals = showAllProposals
    ? sources
    : mostUsed;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const mostUsedResponse = await axiosInstance.get(`/source/most-used?id=${account._id}`);
        if (mostUsedResponse?.data?.length >= 4) {
          setMostUsed(mostUsedResponse.data);
        } else {
          setShowAllProposals(true);
        }

        const sourcesResponse = await axiosInstance.get(`/source?id=${account._id}`);
        setSources(sourcesResponse.data?.source);

      } catch (error) {
        console.log(error);
        setSnacksMessage(error.message || 'Unknown error occurred.');
        setSnackOpen(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const validateAmount = (value) => {
    const amountValue = parseFloat(value);
    if (isNaN(amountValue) || amountValue < 1 || amountValue > 9999999) {
      return 'Amount must be a number between 1 and 9999999.';
    }
    return '';
  };

  const validateSource = (value) => {
    const isValidMongoId = /^[0-9a-fA-F]{24}$/.test(value);
    if (!isValidMongoId) {
      return 'Invalid source value.';
    }
    return '';
  };

  const validateDate = (value) => {
    const isValidDate = !isNaN(Date.parse(value));
    if (!isValidDate) {
      return 'Invalid date format.';
    }
    return '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate fields
    const amountError = validateAmount(amount);
    const sourceError = validateSource(source);
    const dateError = validateDate(date);

    // Set errors
    setErrors({
      amount: amountError,
      source: sourceError,
      date: dateError,
    });

    // Check if there are any errors
    if (amountError || sourceError || dateError) {
      return;
    }

    // Function to append a field to FormData if the value exists
    const appendIfValueExists = (key, value) => {
      if (value !== undefined && value !== null) {
        formData[key] = value;
      }
    };

    // Function to format a date to mm/dd/yy
    const formatDate = (date) => {
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    };

    // Create an object with the validated data
    const formData = {
      amount: parseFloat(amount),
      date: formatDate(date),
    };

    // Append optionnal fields to the FormData instance only if the value exists
    appendIfValueExists('source', source);

    // Call the parent onSave function with the form data
    onSave(formData)
      .then((response) => {
        setSeverity('success');
        setSnacksMessage('Save Successfull.');
        setSnackOpen(true);
        // Reset form fields and errors after submission
        setAmount('');
        setSource('');
        setDate(new Date().toISOString().split('T')[0]);
        setErrors({
          amount: '',
          source: '',
          date: '',
        });
        setIsLoading(false);
        triggerIncomeEffect();
      })
      .catch((error) => {
        setSeverity('error');
        setSnacksMessage(error.message || 'Unknow error occured..');
        setIsLoading(false);
        setSnackOpen(true);
      });
  };

  return (
    <div>
      <SnackMessage message={snackMessage} open={snackOpen} severity={severity} handleClose={handleSnackClose} />
      {
        isLoading
          ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '5rem' }}>
              <CircularProgress />
            </Box>
          )
          : (
            <>
              {
                sources && sources.length >= 1
                  ? (
                    <div>
                      <Container maxWidth="sm" style={{ padding: '2rem', marginTop: '2rem' }}>
                        {/* <Typography variant="h6">Income Form</Typography> */}
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label="Montant"
                                variant="outlined"
                                fullWidth
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                error={Boolean(errors.amount)}
                                helpertext={errors.amount}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel id="source-label">Source</InputLabel>
                                <Select
                                  labelId="source-label"
                                  label="Source"
                                  variant="outlined"
                                  fullWidth
                                  value={source}
                                  onChange={(e) => setSource(e.target.value)}
                                  error={Boolean(errors.source)}
                                  helpertext={errors.source}
                                >
                                  {displayedProposals.map((source) => {
                                    return (
                                      <MenuItem
                                        key={source.id}
                                        value={source._id}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <div style={{ width: '2.75rem', height: '2.75rem', borderRadius: '50%', background: source.color, display: 'grid', placeItems: 'center', color: getTextColor(source.color) }}>
                                          {source.name in SourceIconMapping
                                            ? React.createElement(SourceIconMapping[source.name])
                                            : <CategoryIcon />
                                          }
                                        </div>
                                        <span style={{ marginLeft: '.25rem' }}>{source.name}</span>
                                      </MenuItem>
                                    );
                                  }
                                  )}
                                  {!showAllProposals && (
                                    <Button
                                      variant="outlined"
                                      // size='small'
                                      onClick={() => setShowAllProposals(true)}
                                      style={{ marginTop: '10px', padding: '10px' }}
                                      fullWidth
                                    >
                                      <AddIcon/>
                                    </Button>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Date"
                                variant="outlined"
                                fullWidth
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                error={Boolean(errors.date)}
                                helpertext={errors.date}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button variant="contained" color="primary" type="submit" fullWidth>
                                Sauvegarder le revenu
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </Container>
                    </div >
                  )
                  : <Alert variant="outlined" severity="info">Aucune sources existantes...</Alert>
              }
            </>

          )
      }
    </div >
  );

};

export default IncomeForm;
