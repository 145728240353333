// ShareAccount.js
import React, { useState } from 'react';
import { Typography, TextField, Tooltip, IconButton, FormControlLabel, Switch } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useTheme } from '@emotion/react';
import axiosInstance from '../../common/api';

const ShareAccount = ({ isOwner, account, setIsLoading, setError, setIsOpen, triggerAccountEffect }) => {
  const theme = useTheme();
  const [userName, setUserName] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleShare = async (e) => {
    e.preventDefault();

    try {
      if (!userName) {
        setUsernameError('You have to set a user name.');
        return;
      }

      setIsLoading(true);

      await axiosInstance.post('/account/share', {
        compteId: account._id,
        guestName: userName,
        hasWriteAccess: checked
      });
      setIsLoading(false);
      setUserName('');
      setChecked(false);
      triggerAccountEffect();
    } catch (error) {
      console.log(error);
      const errorMsg = error?.response?.data?.message;
      setError(errorMsg || 'Error during account sharing.');
      setIsLoading(false);
      setIsOpen(true);
    }
  };

  return (
    isOwner && (
      <>
        <Typography 
          color={theme.palette.text.secondary} 
          style={{ fontSize: '.75rem', marginTop: '1.5rem', marginBottom: '.5rem' }}
        >
          Partager <strong style={{ color: theme.palette.primary.main }}>{account.name}</strong> avec
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <TextField
            size='small'
            label="Nom de l'utilisateur"
            variant="filled"
            fullWidth
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            error={Boolean(usernameError)}
            helpertext={usernameError}
          />
          <Tooltip title="Share" style={{ marginLeft: '.75rem' }}>
            <IconButton
              color="primary"
              onClick={handleShare}
            >
              <ShareIcon />
            </IconButton>
          </Tooltip>
        </div>
        <FormControlLabel
          style={{ marginLeft: '0rem', marginTop: '.5rem' }}
          label={
            <Typography 
              variant="body2" 
              style={{ 
                fontSize: '12px', 
                color: 'orange', 
                marginLeft: '.25rem',
                userSelect: 'none'
              }}>
              Permettre à l'invité de mettre à jour les données du compte bancaire sélectionné ?
            </Typography>
          }
          control={<Switch
            checked={checked}
            onChange={handleChange}
            color='warning'
            size='small'
          />}
        />
      </>
    )
  );
};

export default ShareAccount;
