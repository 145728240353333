import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectToLastSeenPage = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const lastLocation = sessionStorage.getItem('lastLocation');
  const defaultLocation = isLoggedIn === true ? '/account' : '/login';

  useEffect(() => {
    if (lastLocation) {
      console.log('Navigate to:', lastLocation);
      navigate(lastLocation);
    } else {
      navigate(defaultLocation);
    }
  }, [navigate, lastLocation, defaultLocation]);

  return null;
};

export default RedirectToLastSeenPage;