import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import HouseIcon from '@mui/icons-material/House';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import AttractionsIcon from '@mui/icons-material/Attractions';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SchoolIcon from '@mui/icons-material/School';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import SavingsIcon from '@mui/icons-material/Savings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import BalanceIcon from '@mui/icons-material/Balance';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import PetsIcon from '@mui/icons-material/Pets';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

const CategoryIcons = [
  {
    name: 'Alimentation',
    value: DinnerDiningIcon
  },
  {
    name: 'Logement',
    value: HouseIcon
  },
  {
    name: 'Transport',
    value: EmojiTransportationIcon
  },
  {
    name: 'Divertissement',
    value: AttractionsIcon
  },
  {
    name: 'Voyages',
    value: LocalAirportIcon
  },
  {
    name: 'Santé',
    value: HealthAndSafetyIcon
  },
  {
    name: 'Éducation',
    value: SchoolIcon
  },
  {
    name: 'Cadeaux',
    value: CardGiftcardIcon
  },
  {
    name: 'Épargne et investissement',
    value: SavingsIcon
  },
  {
    name: 'Dette',
    value: AttachMoneyIcon
  },
  {
    name: 'Assurances',
    value: CardGiftcardIcon
  },
  {
    name: 'Vêtements et accessoires',
    value: CheckroomIcon
  },
  {
    name: 'Frais bancaires',
    value: AssuredWorkloadIcon
  },
  {
    name: 'Impôts',
    value: BalanceIcon
  },
  {
    name: 'Enfants',
    value: ChildCareIcon
  },
  {
    name: 'Animaux de compagnie',
    value: PetsIcon
  },
  {
    name: 'Autre',
    value: DashboardCustomizeIcon
  }
];

const categoryIconMapping = CategoryIcons.reduce((acc, icon) => {
  acc[icon.name] = icon.value;
  return acc;
}, {});

export { CategoryIcons, categoryIconMapping };