import { Alert, AlertTitle, Slide, Snackbar } from '@mui/material';
import React from 'react';

function SnackMessage({ message, severity, open, handleClose, position = {
  vertical: 'top',
  horizontal: 'center',
}, variant, title }) {
  function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }


  return (
    <Snackbar
      anchorOrigin={{ vertical: position.vertical, horizontal: position.horizontal }}
      open={open}
      autoHideDuration={6000}
      TransitionComponent={TransitionDown}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant={variant}
      >
        {title ? (<AlertTitle>{title}</AlertTitle>) : ''}
        
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackMessage;
