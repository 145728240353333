import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { FixedSizeList } from 'react-window';
import AddFormButton from '../AddFormButton';
import ItemListDetail from './ItemListDetail';
import CategoryIcon from '@mui/icons-material/Category';
import { categoryIconMapping } from '../../common/CategoryIcons';
import { SourceIconMapping } from '../../common/SourceIcons';
import { getTextColor } from '../../common/GetTextColor';

const ItemList = ({ apiData, total, parentMode, triggerExpenseEffect, triggerIncomeEffect, account, haswriteAccess }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordeonChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getElementHeight = (data, itemSize = 46) => {
    if (data && (data.length * itemSize < 400)) {
      return data.length * itemSize;
    }
    return 400;
  };

  return (
    <div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', margin: '0 .5rem .5rem 0' }}>
        {haswriteAccess && <AddFormButton parentMode={parentMode} account={account} triggerExpenseEffect={triggerExpenseEffect} triggerIncomeEffect={triggerIncomeEffect} />}
      </div>
      {apiData.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleAccordeonChange(`panel${index}`)}
          sx={{ background: 'transparent', borderRadius: '1rem', boxShadow: 'none', with: '100%' }}
        >
          <AccordionSummary
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            sx={{
              background: item.color,
              color: getTextColor(item.color),
              borderRadius: '1rem'
            }}
          >
            <Typography sx={{
              width: '60%', flexShrink: 0, fontWeight: 'bold', textOverflow: 'ellipsis', display: 'flex',
              alignItems: 'center'
            }}>
              {/* Dynamically select and render the icon based on the category name */}
              {parentMode === 'Expense'
                ? item.name in categoryIconMapping
                  ? React.createElement(categoryIconMapping[item.name])
                  : <CategoryIcon />
                : parentMode === 'Income'
                  ? item.name in SourceIconMapping
                    ? React.createElement(SourceIconMapping[item.name])
                    : <CategoryIcon />
                  : <CategoryIcon />
              }
              <span style={{ marginLeft: '.5rem' }}>{item.name}</span>
            </Typography>
            <Typography sx={{ width: '20%', flexShrink: 0, fontSize: '.8rem', textAlign: 'center' }}>
              {Math.round((item.total / total) * 100)}%
            </Typography>
            <Typography sx={{ width: '20%', flexShrink: 0, fontSize: '.95rem', fontWeight: 600, textAlign: 'right' }}>
              {item.total}<span style={{ fontWeight: '100', fontSize: '.85rem', marginLeft: '.1rem' }}>€</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: '100%', height: getElementHeight(item.data, 80), bgcolor: 'background.paper' }}>
              <FixedSizeList
                height={getElementHeight(item.data, 80)}
                itemSize={80}
                itemCount={item.data.length}
                overscanCount={5}
              >
                {({ index, style }) => {
                  return (
                    <ItemListDetail item={item.data[index]} style={style} index={index} parentMode={parentMode} triggerExpenseEffect={triggerExpenseEffect} triggerIncomeEffect={triggerIncomeEffect} haswriteAccess={haswriteAccess} />
                  );
                }}
              </FixedSizeList>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default ItemList;