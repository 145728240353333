import React, { useEffect, useState } from 'react';
import './BalanceSummary.css';
import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';

const BalanceSummary = ({ solde }) => {
  const [overdrawn, setOverdrawn] = useState(false);
  const [totalSolde, setTotalSolde] = useState(0);
  const [colorSolde, setColorSolde] = useState('hsla(120, 94%, 55%, 0.234)');

  const theme = useTheme();

  useEffect(() => {
    setTotalSolde(solde.in - solde.out);
    setOverdrawn((solde.in - solde.out) < 0);
    const hue = 150 - ((solde.out / solde.in) * 100);
    setColorSolde(`hsla(${hue <= 120 ? hue : 120}, 94%, 55%, 0.234)`);
  }, [solde]);

  return (
    solde
      ? <Typography style={{ 
        color: overdrawn ? theme.palette.text.danger : 'white', 
        fontWeight: overdrawn ? 'bold' : '200',
      }}>
        {Number(totalSolde)?.toFixed(2) || 0}€ 
      </Typography>
      : ''
  );
};

export default BalanceSummary;
