import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { FixedSizeList } from 'react-window';
import axiosInstance from '../../common/api';
import UserListDetail from './UserListDetail';

function UserList({ }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axiosInstance.get('/admin/get-users')
      .then((response) => {
        console.log(response.data);
        setUsers(response.data.users);
      })
      .catch((error) => {
        console.log('Err : ', error);
      })
      .finally((() => { }));
  }, []);

  return (
    <Box
      sx={{ width: '100%', height: 300, bgcolor: 'background.paper' }}
    >
      <FixedSizeList
        height={300}
        itemSize={50}
        itemCount={users.length}
        overscanCount={5}
      >
        {({ index, style }) => (<UserListDetail user={users[index]} style={style} />)}
      </FixedSizeList>
    </Box>
  );
}

export default UserList;