async function hashData(data) {
  const encoder = new TextEncoder();
  const buffer = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);

  // Convert the hash buffer to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
}

async function dehashData(hash) {
  // Convert the hexadecimal string back to a Uint8Array
  const hashArray = hash.match(/.{2}/g).map(byte => parseInt(byte, 16));
  const hashBuffer = new Uint8Array(hashArray).buffer;

  // Convert the Uint8Array buffer to a string
  const decoder = new TextDecoder('utf-8');
  const data = decoder.decode(hashBuffer);

  return data;
}

export const deleteSecureDataFromStorage = (storageKey) => {
  localStorage.removeItem(storageKey);
};

export const SetSecureDataStorage = async (data, storageKey, bypassHash = false) => {
  try {
    const hashedData = await hashData(data);
    localStorage.setItem(storageKey, bypassHash ? data : hashedData);
    return hashedData;
  } catch (error) {
    console.error(`Error hashing data (${storageKey}):`, error.message);
  }
};

export const GetSecureDataStorage = async (storageKey, bypassHash = false) => {
  try {
    const hashedData = localStorage.getItem(storageKey);
    if (!hashedData) {
      return null;
    }
    else if (bypassHash) {
      return hashedData;
    } else {
      return await dehashData(hashedData);
    }
  } catch (error) {
    console.error(`Error dehashing data (${storageKey}):`, error.message);
  }
};
