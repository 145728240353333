import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import BackgroundLetterAvatars from './BackgroundLetterAvatars';
import Switch from '@mui/material/Switch';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LogoutIcon from '@mui/icons-material/Logout';
import CategoryIcon from '@mui/icons-material/Category';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { deleteSecureDataFromStorage } from '../Auth/HashAndStoreData';
import { useTheme } from '@emotion/react';

function Header({ darkMode, handleSwitchChange, isLoggedIn, solde, account, setIsLoggedIn, isAdmin }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    deleteSecureDataFromStorage('app_my_finance_user');
    deleteSecureDataFromStorage('app_my_finance_pass');
    deleteSecureDataFromStorage('access_token');
    deleteSecureDataFromStorage('username');
    setIsLoggedIn(false);
    window.location.reload();
  };

  return (
    <AppBar position="sticky">
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        {isLoggedIn && <BackgroundLetterAvatars solde={solde} account={account} />}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
          {
            isLoggedIn && isAdmin ? (
              <MenuItem key="admin" onClick={handleClose} component={Link} to="/admin">
                <ListItemIcon style={{ color: theme.palette.text.secondary }}>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText>Administrer</ListItemText>
              </MenuItem>
            ) : ''
          }
          {isLoggedIn
            ? ( 
              [
                <MenuItem key="account" onClick={handleClose} component={Link} to="/account">
                  <ListItemIcon style={{ color: theme.palette.text.secondary }}>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText>Compte</ListItemText>
                </MenuItem>,
                <MenuItem key="dashboard" onClick={handleClose} component={Link} to="/dashboard">
                  <ListItemIcon style={{ color: theme.palette.text.secondary }}>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText>Tableau de bord</ListItemText>
                </MenuItem>,
                <MenuItem key="category" onClick={handleClose} component={Link} to="/category">
                  <ListItemIcon style={{ color: theme.palette.text.secondary }}>
                    <CategoryIcon />
                  </ListItemIcon>
                  <ListItemText>Gestion des catégories</ListItemText>
                </MenuItem>,
                <MenuItem key="logout" onClick={logout}>
                  <ListItemIcon style={{ color: theme.palette.text.secondary }}>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>Déconnexion</ListItemText>
                </MenuItem>,
                <MenuItem key="darkMode">
                  <ListItemIcon style={{ color: theme.palette.text.secondary }}>
                    <DarkModeIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <label htmlFor="darkModeSwitch" style={{ cursor: 'pointer' }}>
                    Mode sombre
                    </label>
                    <Switch
                      checked={darkMode}
                      onChange={handleSwitchChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      id="darkModeSwitch"
                    />
                  </ListItemText>
                </MenuItem>
              ]
            )
            : (
              [
                <MenuItem key="login" onClick={handleClose} component={Link} to="/login">
                  <ListItemIcon style={{ color: theme.palette.text.secondary }}>
                    <LoginIcon />
                  </ListItemIcon>
                  <ListItemText>Se connecter</ListItemText>
                </MenuItem>,
                <MenuItem key="register" onClick={handleClose} component={Link} to="/register">
                  <ListItemIcon style={{ color: theme.palette.text.secondary }}>
                    <AppRegistrationIcon />
                  </ListItemIcon>
                  <ListItemText>S'enregistrer</ListItemText>
                </MenuItem>,
                <MenuItem key="darkMode">
                  <ListItemIcon style={{ color: theme.palette.text.secondary }}>
                    <DarkModeIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <label htmlFor="darkModeSwitch" style={{ cursor: 'pointer' }}>
                    Mode sombre
                    </label>
                    <Switch
                      checked={darkMode}
                      onChange={handleSwitchChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      id="darkModeSwitch"
                    />
                  </ListItemText>
                </MenuItem>
              ]
            )}
        </Menu>
      </Toolbar>
    </AppBar >
  );
}

export default Header;
