// AccountSelector.js
import React, { useState } from 'react';
import { Typography, Select, MenuItem, Tooltip, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useTheme } from '@emotion/react';
import axiosInstance from '../../common/api';
import { Link } from 'react-router-dom';

const AccountSelector = ({ isOwner, account, defaultValue, handleAccountChange, accountOwner, accountGuest, setIsLoading, setError, setIsOpen, triggerAccountEffect }) => {
  const theme = useTheme();

  const [isDialogDeleteAccountOpen, setDialogOpen] = useState(false);

  const handleDeleteAccount = async () => {
    setIsLoading(true);
    try {
      await axiosInstance.delete(`/account/${account._id}`);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      const errorMsg = error?.response?.data?.message;
      setError(errorMsg || 'Error during account deletion.');
      setIsOpen(true);
      setIsLoading(false);
    } finally {
      setDialogOpen(false);
      triggerAccountEffect();
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDeleteAccountDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <Typography color={theme.palette.text.secondary}>Compte bancaire</Typography>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Select
          labelId="account-select-label"
          id="account-select"
          value={defaultValue}
          label="My Accounts"
          onChange={handleAccountChange}
          style={{ flex: 1 }}
        >
          {accountOwner && accountOwner.length && accountOwner.map((account =>
            <MenuItem style={{ display: 'flex', alignItems: 'center' }} key={account.name} value={account._id}>
              <PersonIcon style={{ marginRight: '.75rem' }} />
              {account.name}
            </MenuItem>
          ))}
          {accountGuest && accountGuest.length && accountGuest.map((account =>
            <MenuItem style={{ display: 'flex', alignItems: 'center' }} key={account.name} value={account._id}>
              <PeopleIcon style={{ marginRight: '.75rem' }} />
              {account.name}
            </MenuItem>
          ))}
        </Select>
        <Tooltip title="Aller au tableau de bord" style={{ marginLeft: '.75rem' }}>
          <Link to="/dashboard">
            <IconButton color="primary">
              <NextPlanIcon />
            </IconButton>
          </Link>
        </Tooltip>
      </div>
      {isOwner && account && account.name && (
        <Tooltip title='Supprimer le compte'>
          <Button
            color="secondary"
            variant="contained"
            startIcon={<DeleteForeverRoundedIcon />}
            onClick={handleOpenDialog}
            size="small"
            style={{ marginTop: '1.25rem', fontSize: '.75rem' }}
          >
            {account.name}
          </Button>
        </Tooltip>
      )}

      {/* Confirmation Dialog */}
      < Dialog open={isDialogDeleteAccountOpen} onClose={handleCloseDeleteAccountDialog} >
        <DialogTitle>Confirmer</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer ce compte ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteAccountDialog}>Annuler</Button>
          <Button onClick={() => handleDeleteAccount()} color="secondary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AccountSelector;
