import React, { useEffect, useState } from 'react';
import DoughnutChart from '../DoughnutChart';
import axiosInstance from '../../common/api';
import ItemList from '../List/ItemList';
import { Alert, Box, CircularProgress } from '@mui/material';
import SnackMessage from '../../SnackMessage';
import AddFormButton from '../AddFormButton';
import './IncomeItem.css';
import { useNavigate } from 'react-router-dom';

const IncomeItem = ({ type, onTotalChange, account, haswriteAccess }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [total, setApiTotal] = useState(0);
  const [updateEffect, setUpdateEffect] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => { setIsOpen(false); };

  useEffect(() => {
    setIsLoading(true);
    if (!account?._id) {
      navigate('/account', { state: { from: '/dashboard' } });
    } else {
      axiosInstance.get(`/income/period/${account._id}/${type}`)
        .then((response) => {
          setApiTotal(response.data.total);
          setApiData(response.data.formattedData);
          onTotalChange({
            in: response.data.total,
            out: response.data.totalOut,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error.message || 'Error while getting incomes.');
          setIsLoading(false);
          setIsOpen(true);
        });
    }
  }, [type, updateEffect]);

  // Function to trigger the effect again
  const triggerIncomeEffect = () => {
    setUpdateEffect(prevState => !prevState);
  };

  return (
    <div>
      <SnackMessage message={error} severity="warning" open={open} handleClose={handleClose} variant={'filled'} />
      {
        isLoading
          ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '5rem' }}>
              <CircularProgress />
            </Box>
          )
          : (
            <>
              {
                apiData && apiData.length >= 1
                  ? (
                    <div className="flex-container">
                      <DoughnutChart label="Incomes" apiData={apiData} />
                      <ItemList apiData={apiData} total={total} parentMode="Income" triggerIncomeEffect={triggerIncomeEffect} account={account} haswriteAccess={haswriteAccess}/>
                    </div>
                  )
                  : (
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <Alert variant="outlined" severity="info">Rien de trouvé pour le moment concernant cette période</Alert>
                      {haswriteAccess && <AddFormButton parentMode="Income"  account={account}  triggerIncomeEffect={triggerIncomeEffect}/>}
                    </div>
                  )
              }
            </>
          )
      }
    </div>
  );
};

export default IncomeItem;
