import React, { useEffect, useState } from 'react';
import UserList from './UserList/UserList';
import { Button, Divider, Stack, Typography } from '@mui/material';
import axiosInstance from '../common/api';

const Admin = ({ }) => {

  const initSources = (e) => {
    e.preventDefault();

    axiosInstance.get('/admin/source/init-basics')
      .then((response) => { console.log(response.data); })
      .catch((error) => { console.log(error); });
  }; 

  const initCategories = (e) => {
    e.preventDefault();

    axiosInstance.get('/admin/category/init-basics')
      .then((response) => { console.log(response.data); })
      .catch((error) => { console.log(error); });
  }; 

  return (
    <div>
      <h2>Administration</h2>
      <Divider style={{ margin: '1rem 0' }}/>
      <Typography>
        Users List
      </Typography>
      <Divider style={{ margin: '1rem 0' }}/>
      <UserList />
      <Divider style={{ margin: '1rem 0' }}/>
      <Typography>
        Init Sources & Categories
      </Typography>
      <Divider style={{ margin: '1rem 0' }}/>
      <Stack direction="row" spacing={2} style={{ justifyContent: 'center' }}>
        <Button 
          variant="contained"
          onClick={initSources}
        >
          Init Sources
        </Button>
        <Button 
          variant="contained"
          onClick={initCategories}
        >
        Init Categories
        </Button>
      </Stack>
    </div>
  );
};

export default Admin;
