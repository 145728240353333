import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../common/api';
import { deleteSecureDataFromStorage } from '../../Auth/HashAndStoreData';

const UserInfo = ({ user, setIsLoading, setError, setIsOpen, triggerAccountEffect, setIsLoggedIn }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isDialogDeleteUserAccountOpen, setDialogUserOpen] = useState(false);

  const logout = () => {
    deleteSecureDataFromStorage('app_my_finance_user');
    deleteSecureDataFromStorage('app_my_finance_pass');
    deleteSecureDataFromStorage('access_token');
    deleteSecureDataFromStorage('username');
    setIsLoggedIn(false);
    navigate('/login');
  };

  const getFormattedDate = (date) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC'
    };
    return new Date(date).toLocaleString('fr-FR', options);
  };

  const handleDeleteUserAccount = async () => {
    setIsLoading(true);
    try {
      await axiosInstance.delete('/user/delete');
      setIsLoading(false);
      logout();
    } catch (error) {
      const errorMsg = error?.response?.data?.message;
      setError(errorMsg || 'Error during account deletion.');
      setIsOpen(true);
      setIsLoading(false);
    } finally {
      setDialogUserOpen(false);
      triggerAccountEffect();
    }
  };

  const handleOpenUserDialog = () => {
    setDialogUserOpen(true);
  };

  const handleCloseDeleteUserAccountDialog = () => {
    setDialogUserOpen(false);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandCircleDownIcon color="primary" />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography color={theme.palette.text.secondary}>Informations utilisateur</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color={theme.palette.text.secondary}>
          identifiant
          <Typography color={theme.palette.text.primary}>{user._id}</Typography>
        </Typography>
        <Divider style={{ marginBottom: '.75rem' }} />
        <Typography color={theme.palette.text.secondary}>
          nom
          <Typography color={theme.palette.text.primary}>{user.username}</Typography>
        </Typography>
        <Divider style={{ marginBottom: '.75rem' }} />
        <Typography color={theme.palette.text.secondary}>
          status
          <Typography color={theme.palette.text.primary}>{user.role}</Typography>
        </Typography>
        <Divider style={{ marginBottom: '.75rem' }} />
        <Typography color={theme.palette.text.secondary}>
          crée le
          <Typography color={theme.palette.text.primary}>{getFormattedDate(user.createdAt)}</Typography>
        </Typography>
        <Divider style={{ marginBottom: '.75rem' }} />
        <Button
          color="secondary"
          variant="contained"
          startIcon={<DeleteForeverRoundedIcon />}
          onClick={handleOpenUserDialog}
          fullWidth
          style={{ marginTop: '1.25rem', fontSize: '.75rem' }}
        >
          Supprimer votre compte
        </Button>
      </AccordionDetails>

      {/* Confirmation Dialog */}
      < Dialog open={isDialogDeleteUserAccountOpen} onClose={handleCloseDeleteUserAccountDialog} >
        <DialogTitle>Confirmer</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer votre compte et tous les comptes bancaire liés ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteUserAccountDialog}>Annuler</Button>
          <Button onClick={() => handleDeleteUserAccount()} color="secondary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Accordion>
  );
};

export default UserInfo;