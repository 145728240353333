import { Box, CircularProgress, Container, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../common/api';
import SnackMessage from '../SnackMessage';
import UserInfo from './UserInfo/UserInfo';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountSelector from './AccountSelector/AccountSelector';
import GuestList from './GuestList/GuestList';
import ShareAccount from './ShareAccount/ShareAccount';
import CreateAccountForm from './CreateAccountForm/CreateAccountForm';
import { deleteSecureDataFromStorage } from '../Auth/HashAndStoreData';

const Account = ({ setAccount, account, setIsLoggedIn, user, setUser }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOwner, setIsOwner] = useState(false);
  const [hasGuests, setHasGuests] = useState(false);
  const [hasAccount, setHasAccount] = useState(false);
  const [updateEffect, setUpdateEffect] = useState(false);
  const [accountOwner, setAccountOwner] = useState(null);
  const [accountGuest, setAccountGuest] = useState(null);
  const [defaultValue, setDefaultValue] = useState(account?.id);
  const [open, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const logout = () => {
    deleteSecureDataFromStorage('app_my_finance_user');
    deleteSecureDataFromStorage('app_my_finance_pass');
    deleteSecureDataFromStorage('access_token');
    deleteSecureDataFromStorage('username');
    setIsLoggedIn(false);
    navigate('/login');
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAccountChange = async (event) => {
    event.preventDefault();
    axiosInstance.post(
      '/account/default',
      { bankAccountId: event.target.value }
    ).then(res => {
      let account = null;
      if (accountOwner && accountOwner.length) {
        account = accountOwner.find(account => account._id === event.target.value);
      }
      if (!account && accountGuest && accountGuest.length) {
        account = accountGuest.find(account => account._id === event.target.value);
      }
      setDefaultValue(event.target.value);
      setIsOwner(user._id === account.owner);
      setHasGuests(account.guests.length >= 1);
      setAccount(account);
      if (account) {
        localStorage.setItem('current_account', account);
      }
    }).catch(error => console.error(error.message));
  };

  const triggerAccountEffect = () => {
    setUpdateEffect(prevState => !prevState);
  };

  useEffect(() => {
    setIsLoading(true);
    const previousPathname = location?.state?.from;
    axiosInstance.get('/user')
      .then((response) => {
        setUser(response?.data?.user);
        setAccountOwner(response?.data?.bankAccounts?.owner);
        setAccountGuest(response?.data?.bankAccounts?.guest);
        const defaultAcc = response?.data?.bankAccounts?.owner?.length
          ? response?.data?.bankAccounts?.owner.find(acc => acc.default) || response?.data?.bankAccounts?.owner[0]
          : response?.data?.bankAccounts?.guest?.length
            ? response?.data?.bankAccounts?.guest.find(acc => acc.default) || response?.data?.bankAccounts?.guest[0]
            : null;
        if (defaultAcc) {
          setDefaultValue(defaultAcc?._id);
          setIsOwner(response?.data?.user._id === defaultAcc.owner);
          setHasGuests(defaultAcc.guests?.length >= 1);
          setAccount(defaultAcc);
        }
        setIsLoading(false);
        setHasAccount((response?.data?.bankAccounts?.owner?.length || response?.data?.bankAccounts?.guest?.length)
          ? true
          : false
        );

        // Redirections
        if (previousPathname === '/login' && defaultAcc?._id) {
          navigate('/dashboard');
        }
        if (previousPathname === '/category-no-account-id' && defaultAcc?._id) {
          navigate('/category');
        }

      })
      .catch((error) => {
        console.warn('###');
        console.warn(error);
        console.warn('###');
        setError(error.message || 'Error while user info.');
        setIsOpen(true);
        setIsLoading(false);
      });
  }, [updateEffect, location.state]);


  return (
    <div>
      <SnackMessage
        message={error}
        severity="warning"
        open={open}
        handleClose={handleClose}
        variant={'filled'}
      />
      {
        isLoading
          ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '5rem'
              }} >
              <CircularProgress />
            </Box >
          )
          : (
            <Container
              maxWidth="sm"
              style={{ padding: '2rem', marginTop: '1rem' }}
            >
              <UserInfo
                user={user}
                setIsLoggedIn={setIsLoggedIn}
                setIsLoading={setIsLoading}
                setError={setError}
                setIsOpen={setIsOpen}
                triggerAccountEffect={triggerAccountEffect}
              />
              <Divider style={{ marginBottom: '2rem' }} />
              <Box>
                {((accountOwner && accountOwner.length) || (accountGuest && accountGuest.length)) ?
                  <div>
                    <AccountSelector
                      isOwner={isOwner}
                      account={account}
                      defaultValue={defaultValue}
                      handleAccountChange={handleAccountChange}
                      accountOwner={accountOwner}
                      accountGuest={accountGuest}
                      setIsLoading={setIsLoading}
                      setError={setError}
                      setIsOpen={setIsOpen}
                      triggerAccountEffect={triggerAccountEffect}
                    />
                    <GuestList
                      isOwner={isOwner}
                      account={account}
                      hasGuests={hasGuests}
                      setIsLoading={setIsLoading}
                      setError={setError}
                      setIsOpen={setIsOpen}
                      triggerAccountEffect={triggerAccountEffect}
                    />
                    <ShareAccount
                      isOwner={isOwner}
                      account={account}
                      setIsLoading={setIsLoading}
                      setError={setError}
                      setIsOpen={setIsOpen}
                      triggerAccountEffect={triggerAccountEffect}
                    />
                  </div>
                  : ''
                }
                <Divider style={{ marginTop: '.75rem', marginBottom: '.75rem' }} />
                <CreateAccountForm
                  hasAccount={hasAccount}
                  setIsLoading={setIsLoading}
                  setError={setError}
                  setIsOpen={setIsOpen}
                  triggerAccountEffect={triggerAccountEffect}
                />
              </Box>
            </Container>
          )
      }
    </div >
  );
};

export default Account;