import React, { useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { register } from '../../services/authService';
import SnackMessage from '../SnackMessage';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    try {
      const response = await register(formData);

      if (response.error) {
        setErrorMessage(response.error);
        setOpen(true);
      } else {
        navigate('/login');
      }

    } catch (error) {
      console.log('Err - LoginForm: ', error);
      setErrorMessage('An error occurred. Please try again later.');
      setOpen(true);
    }
  };

  return (
    <Container maxWidth="sm" style={{ padding: '2rem', marginTop: '2rem' }}>
      <Typography variant="h6" color="primary">
        Create your account !
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ margin: '1rem 0' }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ margin: '1rem 0' }}
        />
        <SnackMessage message={errorMessage} severity="error" open={open} handleClose={handleClose}/>
        <Button variant="contained" color="primary" type="submit" size="large" fullWidth style={{ marginTop: '1rem' }}>
          Register
        </Button>
      </form>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '1.5rem' }}>
        <Button
          component={Link}
          to="/login"
          variant="contained"
          color="secondary"
          size="small"
          style={{ fontSize: '.7rem' }}
        >
          Login
        </Button>
      </div>
    </Container>
  );
};

export default LoginForm;
