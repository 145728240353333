// CreateAccountForm.js
import React, { useState } from 'react';
import { Container, Paper, Typography, Grid, TextField, Button } from '@mui/material';
import { useTheme } from '@emotion/react';
import axiosInstance from '../../common/api';

const CreateAccountForm = ({ hasAccount, setError, setIsOpen, setIsLoading, triggerAccountEffect }) => {
  const theme = useTheme();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name) {
      setNameError('You have to choose a name.');
      return;
    }

    axiosInstance.post('/account', { name })
      .then((response) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorMsg = error?.response?.data?.message;
        setError(errorMsg || 'Error while saving the account.');
        setIsOpen(true);
        setIsLoading(false);
      })
      .finally(() => {
        setName('');
        triggerAccountEffect();
      });
  };

  return (
    <Container maxWidth="sm" style={{ padding: '2rem', marginTop: '2rem' }}>
      <form onSubmit={handleSubmit}>
        <Paper sx={{ p: 1 }}>
          <Typography color={theme.palette.text.primary}>
            {hasAccount ? 'Ajouter' : 'Créer'} un compte bancaire
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nom du compte"
                variant="filled"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={Boolean(nameError)}
                helperText={nameError}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                {hasAccount ? 'Ajouter' : 'Créer'}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Container>
  );
};

export default CreateAccountForm;
