import React, { useEffect, useState } from 'react';
import DoughnutChart from '../DoughnutChart';
import axiosInstance from '../../common/api';
import ItemList from '../List/ItemList';
import { Alert, Box, CircularProgress } from '@mui/material';
import SnackMessage from '../../SnackMessage';
import AddFormButton from '../AddFormButton';
import './ExpenseItem.css';
import { useNavigate } from 'react-router-dom';

const ExpenseItem = ({ type, onTotalChange, account, haswriteAccess }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [total, setApiTotal] = useState(0);
  const [updateEffect, setUpdateEffect] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    console.log('accountId', account?._id);
    if (!account?._id) {
      navigate('/account', { state: { from: '/dashboard' } });
    } else {
      axiosInstance.get(`/expense/period/${account._id}/${type}`)
        .then((response) => {
          console.log(response.data);
          setApiTotal(response.data.total);
          setApiData(response.data.formattedData);
          setIsLoading(false);
          onTotalChange({
            in: response.data.totalIn,
            out: response.data.total,
          });
        })
        .catch((error) => {
          setError(error.message || 'Error while getting expenses.');
          setIsLoading(false);
        });
    }
  }, [type, updateEffect]);

  // Function to trigger the effect again
  const triggerExpenseEffect = () => {
    setUpdateEffect(prevState => !prevState);
  };

  return (
    <div>
      <SnackMessage message={error} severity="warning" open={open} handleClose={handleClose} variant={'filled'} />
      {
        isLoading
          ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '5rem' }}>
              <CircularProgress />
            </Box>
          )
          : (
            <>
              {
                apiData && apiData.length >= 1
                  ? (
                    <div className="flex-container">
                      <DoughnutChart label="Expenses" apiData={apiData} />
                      <ItemList apiData={apiData} total={total} parentMode="Expense" triggerExpenseEffect={triggerExpenseEffect} account={account} haswriteAccess={haswriteAccess}/>
                    </div>
                  )
                  : (
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <Alert variant="outlined" severity="info">Nothing to show for this period</Alert>
                      {haswriteAccess && <AddFormButton parentMode="Expense" account={account} triggerExpenseEffect={triggerExpenseEffect}/>}
                    </div>
                  )

              }
            </>
          )
      }
    </div>
  );
};

export default ExpenseItem;
