import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import BalanceSummary from './BalanceSummary/BalanceSummary';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export default function BackgroundLetterAvatars({ solde, account }) {
  const username = localStorage.getItem('username');
  return (
    username ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/account" underline="none">
          <Avatar
            sx={{ bgcolor: stringToColor(username), color: '#FFF'}}
            alt={username}
            src="/broken-image.jpg"
          >
          </Avatar >
        </Link>

        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '.5rem' }}>
          <Typography style={{ fontSize: '.75rem' }}>{account?.name || username}</Typography>
          <BalanceSummary solde={solde} />
        </div>
      </div>
    ) : ''
  );
}