import React, { useEffect, useState } from 'react';
import { Chip, Stack, Tab, Tabs, Typography } from '@mui/material';
import TabPanel from './TabPanel';
import ExpenseItem from './Expense/ExpenseItem.js';
import IncomeItem from './Income/IncomeItem.js';
import { useTheme } from '@emotion/react';

function a11yProps(index, name) {
  return {
    id: `tab${name || ''}-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const DashboardContent = ({ solde, setSolde, account, user }) => {
  const [firstTabsValue, setFirstTabsValue] = useState(0);
  const [secondTabsValue, setSecondTabsValue] = useState(0);
  const [soldeType, setSoldeType] = useState('month');
  const [haswriteAccess, setHasWriteAccess] = useState(false);
  const theme = useTheme();

  const handleFirstTabsChange = (event, newValue) => {
    setFirstTabsValue(newValue);
  };

  const handleSecondTabsChange = (event, newValue) => {
    setSecondTabsValue(newValue);

    switch (newValue) {
    case 0:
      setSoldeType('month');
      break;
    case 1:
      setSoldeType('week');
      break;
    case 2:
      setSoldeType('day');
      break;
    case 3:
      setSoldeType('year');
      break;
    default:
      break;
    }
  };

  const onTotalChange = (value) => {
    setSolde({
      in: value.in,
      out: value.out,
      type: soldeType
    });
  };

  const getMonthName = () => {
    return new Date().toLocaleDateString('fr-FR', {
      month: 'long',
    });
  };

  const getWeekBounds = () => {
    const today = new Date();
    const currentDay = today.getDay(); // Numéro du jour actuel (0 = dimanche, 1 = lundi, ..., 6 = samedi)

    // Calcul du début de la semaine (lundi)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - currentDay + (currentDay === 0 ? -6 : 1));

    // Calcul de la fin de la semaine (dimanche)
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (7 - currentDay));

    // Récupération des numéros des jours de la semaine
    const daysOfWeek = Array.from({ length: 7 }, (_, i) => (i + 1) % 7); // 1 = lundi, 2 = mardi, ..., 0 = dimanche

    return `${startOfWeek.getDate()}/${endOfWeek.getDate()}`;
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  const getDayName = () => {
    return new Date().toLocaleDateString('fr-FR', {
      weekday: 'long'
    });
  };

  useEffect(() => {
    if (account) {
      const isAuthGuest = user?._id && account?.guests?.length >= 1
        ? !!account.guests.find(guest => guest.user_id === user._id && guest.accessRight === 'write')
        : false;
      const isOwner = user && user._id === account?.owner;
      setHasWriteAccess(isOwner || isAuthGuest || false);
    }
  }, []);

  return (
    <div>

      <Tabs
        value={firstTabsValue}
        onChange={handleFirstTabsChange}
        aria-label="In or Out tabs"
        style={{ marginTop: '10px', position: 'sticky', top: '50px', background: theme.palette.background.default, zIndex: 100 }}
        centered
      >
        <Tab
          label={
            <div>
              <Typography>
                Depenses
              </Typography>
              <Typography
                style={{ fontSize: '14px', fontWeight: 600, textAlign: 'center', color: theme.palette.text.primary  }}
              >
                {solde.out}
                <span style={{ fontSize: '.65rem', fontWeight: 100, marginLeft: '.1rem' }}>€</span>
              </Typography>
            </div>
          }
          {...a11yProps(0, 'first')}
        />
        <Tab
          label={
            <div>
              <Typography>
                  Revenus
              </Typography>
              <Typography
                style={{ fontSize: '14px', fontWeight: 600, textAlign: 'center', color: theme.palette.text.primary }}
              >
                {solde.in}
                <span style={{ fontSize: '.65rem', fontWeight: 100, marginLeft: '.1rem' }}>€</span>
              </Typography>
            </div>
          }
          {...a11yProps(1, 'first')}
        />
      </Tabs>
      <TabPanel value={firstTabsValue} index={0}>
        <Tabs
          value={secondTabsValue}
          onChange={handleSecondTabsChange}
          aria-label="Period tabs"
          variant="scrollable"
          scrollButtons="auto"
          style={{ maxWidth: '360px', margin: 'auto' }}
        >
          <Tab
            label={
              <div>
                <Typography>
                  Mois
                </Typography>
                <Typography variant='p' style={{ fontSize: '.65rem', color: theme.palette.text.primary }}>
                  {getMonthName()}
                </Typography>
              </div>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <div>
                <Typography>
                  Semaine
                </Typography>
                <Typography variant='p' style={{ fontSize: '.65rem', color: theme.palette.text.primary }}>
                  {getWeekBounds()}
                </Typography>
              </div>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <div>
                <Typography>
                  Jour
                </Typography>
                <Typography variant='p' style={{ fontSize: '.65rem', color: theme.palette.text.primary }}>
                  {getDayName()}
                </Typography>
              </div>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <div>
                <Typography>
                  Année
                </Typography>
                <Typography variant='p' style={{ fontSize: '.65rem', color: theme.palette.text.primary }}>
                  {getYear()}
                </Typography>
              </div>
            }
            {...a11yProps(3)}
          />
        </Tabs>
        <TabPanel value={secondTabsValue} index={0}>
          <ExpenseItem type="month" onTotalChange={onTotalChange} account={account} haswriteAccess={haswriteAccess} />
        </TabPanel>
        <TabPanel value={secondTabsValue} index={1}>
          <ExpenseItem type="week" onTotalChange={onTotalChange} account={account} haswriteAccess={haswriteAccess} />
        </TabPanel>
        <TabPanel value={secondTabsValue} index={2}>
          <ExpenseItem type="day" onTotalChange={onTotalChange} account={account} haswriteAccess={haswriteAccess} />
        </TabPanel>
        <TabPanel value={secondTabsValue} index={3}>
          <ExpenseItem type="year" onTotalChange={onTotalChange} account={account} haswriteAccess={haswriteAccess} />
        </TabPanel>
      </TabPanel>
      <TabPanel value={firstTabsValue} index={1}>
        <Tabs
          value={secondTabsValue}
          onChange={handleSecondTabsChange}
          aria-label="Period tabs"
          variant="scrollable"
          scrollButtons="auto"
          style={{ maxWidth: '360px', margin: 'auto' }}
        >
          <Tab
            label={
              <div>
                <Typography>
                  Mois
                </Typography>
                <Typography variant='p' style={{ fontSize: '.65rem', color: theme.palette.text.primary }}>
                  {getMonthName()}
                </Typography>
              </div>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <div>
                <Typography>
                  Semaine
                </Typography>
                <Typography variant='p' style={{ fontSize: '.65rem', color: theme.palette.text.primary }}>
                  {getWeekBounds()}
                </Typography>
              </div>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <div>
                <Typography>
                  Jour
                </Typography>
                <Typography variant='p' style={{ fontSize: '.65rem', color: theme.palette.text.primary }}>
                  {getDayName()}
                </Typography>
              </div>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <div>
                <Typography>
                  Année
                </Typography>
                <Typography variant='p' style={{ fontSize: '.65rem', color: theme.palette.text.primary }}>
                  {getYear()}
                </Typography>
              </div>
            }
            {...a11yProps(3)}
          />
        </Tabs>
        <TabPanel value={secondTabsValue} index={0}>
          <IncomeItem type="month" onTotalChange={onTotalChange} account={account} haswriteAccess={haswriteAccess} />
        </TabPanel>
        <TabPanel value={secondTabsValue} index={1}>
          <IncomeItem type="week" onTotalChange={onTotalChange} account={account} haswriteAccess={haswriteAccess} />
        </TabPanel>
        <TabPanel value={secondTabsValue} index={2}>
          <IncomeItem type="day" onTotalChange={onTotalChange} account={account} haswriteAccess={haswriteAccess} />
        </TabPanel>
        <TabPanel value={secondTabsValue} index={3}>
          <IncomeItem type="year" onTotalChange={onTotalChange} account={account} haswriteAccess={haswriteAccess} />
        </TabPanel>
      </TabPanel>
    </div>
  );
};

export default DashboardContent;
