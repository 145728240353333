import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemButton } from '@mui/material';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import axiosInstance from '../../common/api';
import { useState } from 'react';

const UserListDetail = ({ user, style, index }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDelete = async (id) => {
    console.log('Delete User: ', id);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>{user.username}</p>
          <p style={{ fontSize: '.75rem', color: 'secondary' }}>{new Date(user.createdAt).toLocaleDateString('fr-FR', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          })}
          </p>
        </div>
        <DeleteForeverRoundedIcon color="secondary" onClick={handleOpenDialog} />
      </ListItemButton>

      {/* Confirmation Dialog */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={() => handleDelete(item._id)} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </ListItem>
  );
};

export default UserListDetail;