import React, { useEffect, useState } from 'react';
import { Button, Container, FormControl, TextField, InputLabel, Select, MenuItem, Grid, Alert, CircularProgress, Box } from '@mui/material';
import axiosInstance from '../../common/api';
import SnackMessage from '../../SnackMessage';
import CategoryIcon from '@mui/icons-material/Category';
import AddIcon from '@mui/icons-material/Add';
import { categoryIconMapping } from '../../common/CategoryIcons';
import { getTextColor } from '../../common/GetTextColor';

const defaultRecurentValues = ['journalière', 'hebdomadaire', 'mensuelle'];

const ExpenseForm = ({ onSave, triggerExpenseEffect, account }) => {
  const [categories, setCategories] = useState([]);
  const [mostUsed, setMostUsed] = useState([]);
  const [amount, setAmount] = useState('');
  const [showAllProposals, setShowAllProposals] = useState(false);
  const [category, setCategory] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]); // Set default date to today
  const [description, setDescription] = useState('');
  const [recurrence, setRecurrence] = useState('');
  const [dayOfWeek, setDayOfWeek] = useState('');
  const [dayOfMonth, setDayOfMonth] = useState('');
  const [errors, setErrors] = useState({
    amount: '',
    category: '',
    description: '',
    date: '',
    recurrence: '',
    dayOfMonth: '',
    dayOfWeek: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnacksMessage] = useState(null);
  const [severity, setSeverity] = useState('error');
  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const displayedProposals = showAllProposals
    ? categories
    : mostUsed;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const mostUsedResponse = await axiosInstance.get(`/category/most-used?id=${account._id}`);
        if (mostUsedResponse?.data?.length >= 4) {
          setMostUsed(mostUsedResponse.data);
        } else {
          setShowAllProposals(true);
        }

        const categoriesResponse = await axiosInstance.get(`/category?id=${account._id}`);
        setCategories(categoriesResponse.data?.category);

      } catch (error) {
        console.log(error);
        setSnacksMessage(error.message || 'Unknown error occurred.');
        setSnackOpen(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const validateAmount = (value) => {
    const amountValue = parseFloat(value);
    if (isNaN(amountValue) || amountValue < 1 || amountValue > 9999999) {
      return 'Amount must be a number between 1 and 9999999.';
    }
    return '';
  };

  const validateCategory = (value) => {
    const isValidMongoId = /^[0-9a-fA-F]{24}$/.test(value);
    if (!isValidMongoId) {
      return 'Invalid category value.';
    }
    return '';
  };

  const validateDescription = (value) => {
    if (value.length > 255) {
      return `Description must be at most 255 characters. (${value.length} chars)`;
    }
    return '';
  };

  const validateDate = (value) => {
    const isValidDate = !isNaN(Date.parse(value));
    if (!isValidDate) {
      return 'Invalid date format.';
    }
    return '';
  };

  const validateRecurrence = (value) => {
    if (value && !defaultRecurentValues.includes(value)) {
      return 'Recurence must be one of the following: ' + defaultRecurentValues.join(', ');
    }
    return '';
  };

  const validateDayOfMonth = (recurrence, value) => {
    if (recurrence === 'monthly') {
      if (!value) {
        return 'Day in month is required for monthly recurrence.';
      }
      else if (value < 1 || value > 30) {
        return 'Day in month is unvalid.';
      }
    }
    return '';
  };

  const validateDayOfWeek = (recurrence, value) => {
    if (recurrence === 'weekly' && !value) {
      return 'Day of Week is required for weekly recurrence.';
    }
    return '';

  };

  const handleRecurrenceChange = (value) => {
    setRecurrence(value);
    setDayOfWeek('');
    setDayOfMonth('');
  };

  const getMonthOptions = () => {
    const monthOptions = [];

    for (let month = 0; month < 30; month++) {
      monthOptions.push({
        value: month + 1,
        name: month + 1,
      });
    }

    return monthOptions;
  };

  const getDayOfWeekOptions = () => {
    const dayOfWeekOptions = [];

    for (let day = 0; day < 7; day++) {
      const date = new Date(2000, 0, day + 1); // Months are zero-based, days start from 1
      const dayOfWeekName = date.toLocaleString('fr-FR', { weekday: 'long' });

      dayOfWeekOptions.push({
        value: day + 1,
        name: dayOfWeekName,
      });
    }

    return dayOfWeekOptions;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Validate fields
    const amountError = validateAmount(amount);
    const descriptionError = validateDescription(description);
    const dateError = validateDate(date);
    const categoryError = validateCategory(category);
    const recurrenceError = validateRecurrence(recurrence);
    const dayOfMonthError = validateDayOfMonth(recurrence, dayOfMonth);
    const dayOfWeekError = validateDayOfWeek(recurrence, dayOfWeek);

    // Set errors
    setErrors({
      amount: amountError,
      category: categoryError,
      description: descriptionError,
      date: dateError,
      recurrence: recurrenceError,
      dayOfMonth: dayOfMonthError,
      dayOfWeek: dayOfWeekError
    });

    // Check if there are any errors
    if (amountError || categoryError || descriptionError || dateError || recurrenceError || dayOfWeekError || dayOfMonthError) {
      setIsLoading(false);
      return;
    }

    // Create an object with the form data
    const formData = {};

    // Function to append a field to FormData if the value exists
    const appendIfValueExists = (key, value) => {
      if (value !== undefined && value !== null) {
        formData[key] = value;
      }
    };

    // Function to format a date to mm/dd/yy
    const formatDate = (date) => {
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    };

    // Append required fields to the FormData instance
    formData['amount'] = parseFloat(amount);
    formData['category'] = category;
    formData['date'] = formatDate(date);

    // Append optionnal fields to the FormData instance only if the value exists
    appendIfValueExists('description', description);
    appendIfValueExists('recurrence', recurrence);
    appendIfValueExists('dayOfMonth', dayOfMonth);
    appendIfValueExists('dayOfWeek', dayOfWeek);

    // Call the parent onSave function with the form data
    onSave(formData)
      .then((response) => {
        setSeverity('success');
        setSnacksMessage('Save Successfull.');
        setSnackOpen(true);
        // Reset the form fields after saving
        setAmount('');
        setCategory('');
        setDate(new Date().toISOString().split('T')[0]);
        setDescription('');
        setRecurrence('');
        setErrors({
          amount: '',
          category: '',
          description: '',
          date: '',
          recurrence: '',
          dayOfMonth: '',
          dayOfWeek: '',
        });
        setIsLoading(false);
        console.log('ExpenseForm: ', typeof triggerExpenseEffect);
        triggerExpenseEffect();
      })
      .catch((error) => {
        setSeverity('error');
        setSnacksMessage(error.message || 'Unknow error occured..');
        setIsLoading(false);
        setSnackOpen(true);
      });
  };

  return (
    <div>
      <SnackMessage message={snackMessage} open={snackOpen} severity={severity} handleClose={handleSnackClose} />
      {
        isLoading
          ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '5rem' }}>
              <CircularProgress />
            </Box>
          )
          : (
            <>
              {
                categories && categories.length >= 1
                  ? (
                    <div>
                      <Container maxWidth="sm" style={{ padding: '2rem', marginTop: '2rem' }}>
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label="Montant"
                                variant="outlined"
                                fullWidth
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                error={Boolean(errors.amount)}
                                helpertext={errors.amount}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Date"
                                variant="outlined"
                                fullWidth
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                error={Boolean(errors.date)}
                                helpertext={errors.date}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Description"
                                variant="outlined"
                                fullWidth
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                error={Boolean(errors.description)}
                                helpertext={errors.description}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel id="category-label">Catégorie</InputLabel>
                                <Select
                                  labelId="category-label"
                                  label="Category"
                                  value={category || ''}
                                  onChange={(e) => setCategory(e.target.value)}
                                  error={Boolean(errors.category)}
                                  helpertext={errors.category}
                                >
                                  {displayedProposals.map((category) => {
                                    return (
                                      <MenuItem
                                        key={category._id}
                                        value={category._id}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <div style={{ width: '2.75rem', height: '2.75rem', borderRadius: '50%', background: category.color, display: 'grid', placeItems: 'center', color: getTextColor(category.color) }}>
                                          {category.name in categoryIconMapping
                                            ? React.createElement(categoryIconMapping[category.name])
                                            : <CategoryIcon />
                                          }
                                        </div>
                                        <span style={{ marginLeft: '.25rem' }}>{category.name}</span>
                                      </MenuItem>
                                    );
                                  }
                                  )}
                                  {!showAllProposals && (
                                    <Button
                                      variant="outlined"
                                      // size='small'
                                      onClick={() => setShowAllProposals(true)}
                                      style={{ marginTop: '10px', padding: '10px' }}
                                      fullWidth
                                    >
                                      <AddIcon/>
                                    </Button>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                            {/* TODO: Gerer la récurence */}
                            {/* <Grid item xs={12}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel id="recurrence-label">Récurrence</InputLabel>
                                <Select
                                  labelId="recurrence-label"
                                  label="Recurrence"
                                  value={recurrence}
                                  onChange={(e) => handleRecurrenceChange(e.target.value)}
                                  error={Boolean(errors.recurrence)}
                                  helpertext={errors.recurrence}
                                >
                                  <MenuItem value="">--aucune--</MenuItem>
                                  {defaultRecurentValues.map((value) => (
                                    <MenuItem key={value} value={value}>{value}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {recurrence === 'weekly' && (
                              <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel id="dayOfWeek-label">Jour de la semaine</InputLabel>
                                  <Select
                                    labelId="dayOfWeek-label"
                                    label="Jour de la semaine"
                                    value={dayOfWeek}
                                    onChange={(e) => setDayOfWeek(e.target.value)}
                                    error={Boolean(errors.dayOfWeek)}
                                    helpertext={errors.dayOfWeek}
                                  >
                                    {getDayOfWeekOptions().map((day) => (
                                      <MenuItem key={day.value} value={day.value}>{day.name}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            )}
                            {recurrence === 'monthly' && (
                              <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel id="dayOfMonth-label">Jour du mois</InputLabel>
                                  <Select
                                    labelId="dayOfMonth-label"
                                    label="Jour du mois"
                                    value={dayOfMonth}
                                    onChange={(e) => setDayOfMonth(e.target.value)}
                                    error={Boolean(errors.dayOfMonth)}
                                    helpertext={errors.dayOfMonth}
                                  >
                                    {getMonthOptions().map((month) => (
                                      <MenuItem key={month.value} value={month.value}>{month.name}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            )} */}
                            <Grid item xs={12}>
                              <Button size="large" variant="contained" color="primary" type="submit" fullWidth>
                                Sauvegarder la dépense
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </Container>
                    </div >
                  )
                  : <Alert variant="outlined" severity="info">Aucune categories existantes...</Alert>
              }
            </>
          )
      }
    </div >
  );
};

export default ExpenseForm;
