import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemButton } from '@mui/material';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import axiosInstance from '../../common/api';
import { useState } from 'react';

const ItemListDetail = ({ item, style, index, parentMode, triggerExpenseEffect, triggerIncomeEffect, haswriteAccess }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleDelete = async (id) => {
    try {
      // Call the delete API with the item ID
      await axiosInstance.delete(`/${parentMode.toLowerCase()}/${id}`);
      if (parentMode === 'Income') {
        triggerIncomeEffect();
      } else {
        triggerExpenseEffect();
      }
    } catch (error) {
      console.error('Error during deletion:', error);
    } finally {
      setDialogOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <ListItem style={{ ...style, borderBottom: '1px solid grey' }} key={index} component="div" disablePadding>
      <ListItemButton>
        <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', width: '100%' }}>
          <p style={{ fontWeight: 'bold' }}>{item.amount}<span style={{ fontWeight: '100', fontSize: '.85rem', marginLeft: '.1rem' }}>€</span></p>
          <p style={{ fontSize: '.85rem', textAlign: 'left', width: '90%', padding: '0 .5rem' }}>{item.description}</p>
          <p style={{ fontSize: '.75rem', color: 'secondary' }}>{new Date(item.date).toLocaleDateString('fr-FR', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          })}</p>
        </div>
        {haswriteAccess && <DeleteForeverRoundedIcon color="secondary" onClick={handleOpenDialog} />}
      </ListItemButton>

      {/* Confirmation Dialog */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirmer</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer {parentMode === 'Income' ? 'ce revenu ?' : 'cette dépense ?'}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={() => handleDelete(item._id)} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </ListItem>
  );
};

export default ItemListDetail;