import { useTheme } from '@emotion/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ label, apiData, maxWidth }) => {
  const theme = useTheme();

  const data = {
    labels: apiData.map(data => data.name),
    datasets: [{
      label: label,
      data: apiData.map(item => item.total),
      backgroundColor: apiData.map(data => data.color),
      borderColor: theme.palette.background.default,
      borderWidth: 2,
      hoverOffset: 12
    }]
  };

  const mWidth = maxWidth || '369px';

  return (
    <div style={{ margin: 'auto', maxWidth: mWidth }}>
      <Doughnut data={data}/>
    </div>
  );
};

export default DoughnutChart;