import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../common/api';
import { useTheme } from '@emotion/react';

function AddCtegoryAndSource({ account, user }) {
  const [type, setType] = useState('category');
  const [name, setName] = useState('');
  const [color, setColor] = useState('');
  const [categories, setCategories] = useState([]);
  const [sources, setSources] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isOwner, setIsOwner] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDeleteItem = (type, id) => {
    setItemToDelete({ type, id });
    setIsDialogOpen(true);
  };

  const createCategoryOrSource = () => {
    axiosInstance.post(`/${type}/add`, {
      name: name,
      color: color,
      accountId: account._id
    })
      .then((response) => {
        type === 'category'
          ? getCategories()
          : getSources();
        setName('');
        setColor('');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategories = () => {
    const url = account?._id
      ? `/category?id=${account._id}`
      : '/category';
    axiosInstance.get(url)
      .then((response) => {
        setCategories(response.data.category);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSources = () => {
    const url = account?._id
      ? `/source?id=${account._id}`
      : '/source';
    axiosInstance.get(url)
      .then((response) => {
        setSources(response.data.source);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteItem = () => {
    if (itemToDelete) {
      axiosInstance.delete(`/${itemToDelete.type}/${itemToDelete.id}`)
        .then((response) => {
          type === 'category'
            ? getCategories()
            : getSources();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          handleCloseDialog();
          setIsOwner(account?.owner?.id === user?.id);
        });
    } else {
      console.log({ itemToDelete });
    }
  };

  useEffect(() => {
    if (!account || !user) {
      navigate('/account', { state: { from: '/category-no-account-id' } });
    }
    getCategories();
    getSources();
    setIsOwner(account?.owner === user?._id);
  }, []);

  return (
    <div>
      <Box sx={{ margin: 'auto', p: 2, maxWidth: '20rem' }}>

        <Typography
          style={{
            color: theme.palette.text.primary,
            padding: '1rem'
          }}
        >
          {isOwner ? 'Ajouter une catégorie ou une souce' : 'Explorer les catégories et les souces'}
        </Typography>
        <ToggleButtonGroup
          color="primary"
          value={type}
          exclusive
          onChange={(e) => setType(e.target.value)}
          aria-label="Platform"
          fullWidth
        >
          <ToggleButton value="category">Catégorie</ToggleButton>
          <ToggleButton value="source">Source</ToggleButton>
        </ToggleButtonGroup>

        {/* Form */}
        {isOwner && <form>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Color"
            type="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={createCategoryOrSource}
            fullWidth
          >
            Créer une {type === 'category' ? 'catégorie' : 'source'}
          </Button>
        </form>}
      </Box>

      {type === 'category'
        ? (
          <Box sx={{ margin: 'auto', p: 1, maxWidth: '20rem' }}>
            <Typography
              style={{
                color: theme.palette.text.primary,
                padding: '.2rem',
                fontWeight: 600
              }}
            >
              Catégories
            </Typography>
            {/* List */}
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              aria-label="contacts"
            >
              {categories.map((category) => (
                <ListItem
                  key={category._id}
                  disablePadding
                  style={{ margin: '.1rem', color: theme.palette.text.primary }}
                  secondaryAction={isOwner && <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteItem('category', category._id)}
                  >
                    {!category.isBasic && <DeleteIcon />}
                  </IconButton>
                  }
                >
                  <ListItemButton>
                    <div style={{
                      backgroundColor: category.color,
                      height: '20px',
                      width: '20px'
                    }}>
                    </div>
                    <ListItemText inset primary={category.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        )
        : (
          <Box sx={{ margin: 'auto', p: 1, maxWidth: '20rem' }}>
            <Typography
              style={{
                color: theme.palette.text.primary,
                padding: '.2rem',
                fontWeight: 600
              }}
            >
              Sources
            </Typography>
            {/* List */}
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              aria-label="contacts"
            >
              {sources.map((source) => (
                <ListItem
                  key={source._id}
                  disablePadding
                  style={{ margin: '.1rem', color: theme.palette.text.primary }}
                  secondaryAction={isOwner && <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteItem('source', source._id)}
                  >
                    {!source.isBasic && <DeleteIcon />}
                  </IconButton>
                  }
                >
                  <ListItemButton>
                    <div style={{
                      backgroundColor: source.color,
                      height: '20px',
                      width: '20px'
                    }}>
                    </div>
                    <ListItemText inset primary={source.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        )
      }

      {/* Confirmation Dialog */}
      < Dialog open={isDialogOpen} onClose={handleCloseDialog} >
        <DialogTitle>Confirmer</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer cette {type === 'category' ? 'categorie' : 'source'} ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={deleteItem} color="secondary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default AddCtegoryAndSource;
