import config from '../config';

export const login = async (credentials) => {
  // Make an API call to the login endpoint
  try {
    const response = await fetch(`${config.apiBaseURL}/login`, {
      method: 'POST',
      body: credentials, 
    });

    if (response.ok) {
      // Login successful, extract the token from the response
      const data = await response.json();
      const accessToken = data.token;
      const username = data.user.name;

      // Store the access token (e.g., in local storage)
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('username', username);

      return { error: null, success: true, user: data.user };
    } else {
      console.log('Err - login/response: ', response);
      return { error: 'Login failed. Please check your credentials.', success: false };
    }

  } catch (error) {
    console.log('Err - login: ', error);
    return { error: 'An error occurred. Please try again later.', success: false };
  }
};

export const register = async (userData) => {
  // Make an API call to the registration endpoint
  try {
    const response = await fetch(`${config.apiBaseURL}/register`, {
      method: 'POST',
      body: userData,
    });

    if (response.ok) {
      const data = await response.json();
      if (data.message === 'Utilisateur enregistré avec succès') {
        return { error: null, success: true };
      }
      return { error: data.message, success: false };
    } else {
      console.log('Err - register/response: ', response);
      return { error: 'Registration failed. Please check your credentials.', success: false };
    }

  } catch (error) {
    console.log('Err - register: ', error);
    return { error: 'An error occurred. Please try again later.', success: false };
  }
};
