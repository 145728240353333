import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './theme';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header/Header';
import ErrorBoundary from './components/ErrorBoundary';

import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Account from './components/Account/Account';
import RedirectToLastSeenPage from './components/RedirectToLastSeenPage';
import DashboardContent from './components/Dashboard/DashboardContent';
import axiosInstance from './components/common/api';
import Admin from './components/Admin/Admin';
import AddCtegoryAndSource from './components/CategoryAndSource/AddCtegoryAndSource';

function App() {
  const [darkMode, setDarkMode] = useState(JSON.parse(localStorage.getItem('darkmode')) || false);
  const [solde, setSolde] = useState('');
  const [account, setAccount] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('access_token') ? true : false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState(null);

  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    localStorage.setItem('darkmode', isChecked);
    setDarkMode(isChecked);
  };

  const theme = darkMode ? darkTheme : lightTheme;

  useEffect(() => {
    axiosInstance.get('/user')
      .then((response) => {
        if (response?.data?.user) {
          setUser(response?.data?.user);
          if (response?.data?.user?.role === 'Admin') {
            setIsAdmin(true);
          }
        }
        const ownerAccounts = response?.data?.bankAccounts?.owner;
        const guestAccounts = response?.data?.bankAccounts?.guest;
        let lastUsedAccount = ownerAccounts?.length
          ? ownerAccounts[0]
          : guestAccounts?.length
            ? guestAccounts[0]
            : null;


        if (Array.isArray(ownerAccounts)) {
          ownerAccounts.concat(Array.isArray(guestAccounts) ? guestAccounts : [])
            .find(account => account.lastUsed === true);
        }
        lastUsedAccount = ownerAccounts ? ownerAccounts : lastUsedAccount;

        if (lastUsedAccount) {
          setAccount(lastUsedAccount);
        }
      })
      .catch((error) => {
        console.log(error.message || 'Error while getting user info.');
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
        <Router>
          <Header darkMode={darkMode} handleSwitchChange={handleSwitchChange} isLoggedIn={isLoggedIn} solde={solde} account={account} setIsLoggedIn={setIsLoggedIn} isAdmin={isAdmin} />
          <ErrorBoundary>
            <Routes>
              {/* Public routes accessible to everyone */}
              <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setIsAdmin={setIsAdmin} />} />
              <Route path="/register" element={<Register />} />

              {/* Authenticated routes */}
              {isLoggedIn && (
                <>
                  <Route path="/account" element={<Account setAccount={setAccount} account={account} setIsLoggedIn={setIsLoggedIn} user={user} setUser={setUser} />} />
                  <Route path="/dashboard" element={<DashboardContent solde={solde} setSolde={setSolde} account={account} user={user} />} />
                  <Route path="/category" element={<AddCtegoryAndSource account={account} user={user} />} />
                </>
              )}
              {isLoggedIn && isAdmin && (
                <Route path="/admin" element={<Admin />} />
              )}

              {/* Fallback for unmatched routes */}
              <Route path="*" element={<RedirectToLastSeenPage isLoggedIn={isLoggedIn} />} />
            </Routes>

          </ErrorBoundary>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
