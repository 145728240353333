import axios from 'axios';
import config from '../../config';

// http://localhost:1337
const axiosInstance = axios.create({
  baseURL: config.apiBaseURL
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Vous pouvez intercepter et modifier la requête ici
    // Par exemple, vous pouvez ajouter des en-têtes ou des paramètres à toutes les requêtes
    // config.headers['Authorization'] = 'Bearer ' + token;
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = token;
    }

    return config;
  },
  (error) => {
    console.log('Err Axios Request:', error.message);
    // Gestion des erreurs de requête
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Intercepteur de réponse : gérer les réponses réussies ici
    return response;
  },
  (error) => {
    console.error('AXIOS INTERCEPTOR');
    console.error(error.message);
    const formattedErrorMessage = {
      message: error?.response?.data?.message || error?.response?.data || error?.response || error
    };

    // Intercepteur de réponse : gérer les erreurs de réponse ici
    if (error.response) {
      formattedErrorMessage.statusCode = error.response.status;
      if (formattedErrorMessage.statusCode === 403 || formattedErrorMessage.statusCode === 401) {
        console.log('formattedErrorMessage', formattedErrorMessage);
        // Code 403 : Déconnexion de l'utilisateur 
        // Exemple de déconnexion :
        // Supprimez le token d'accès, redirigez l'utilisateur vers la page de connexion, etc.
        // Redirigez l'utilisateur vers la page de connexion (vous pouvez utiliser React Router pour cela)
        if (formattedErrorMessage?.message === 'Accès non autorisé') {
          console.log('No token');
        }
        else if (formattedErrorMessage?.message === 'Token invalide') {
          console.log('Invalid token.');
          localStorage.removeItem('access_token');
        }
        else if (formattedErrorMessage?.message === 'Access denied') {
          console.log('Role Invalid');
        }
        // window.location.replace('/login');
      }
    }
    // console.error('Err Axios Response:', error);
    return Promise.reject(formattedErrorMessage);
  }
);

export default axiosInstance;