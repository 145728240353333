function getTextColor(hexColor) {
  if (hexColor) {
    // Convert hex to RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
  
    // Calculate relative luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
    // Use a threshold to decide whether to use black or white text
    return luminance > 0.5 ? 'black' : 'white';
  }
}

export { getTextColor };
